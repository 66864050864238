import noUiSlider from 'noUiSlider';
import { formatNumber } from '../utils/helpers';

const invalidClass = 'invalid';

/**
 * Check validation of single form field
 * @param {HTMLElement | Object} field - Form field element or event object.
 *
 * @returns {boolean}
 */
function checkValidation(input) {
    let valid = true;
    let val = '';
    const field = input.target ? input.target : input;

    field.classList.remove(invalidClass);

    switch (field.nodeName) {
        case 'INPUT':
        case 'TEXTAREA':
        case 'SELECT':
        default:
            val = field.value;
            break;
    }

    if (field.type == 'checkbox' && !field.checked || val.trim() == '') {
        valid = false;
        field.classList.add(invalidClass);
        field.focus();
    }

    return valid;
}

/**
 * Runs [required] fields within form through validation function
 *
 * @param {HTMLElement} form - Form element to validate  *
 * @returns {boolean}
 */
export function validateForm(form) {
    const requiredFields = form.querySelectorAll('[required]');
    let valid = true;

    for (let i = 0; i < requiredFields.length; i++) {
        const field = requiredFields[i];

        valid = checkValidation(field);
        if (!valid) {
            break;
        }
    }

    return valid;
}

/**
 * This is ment as an 'input' eventhandler set on textareas,
 * to auto increase it's height depending on the users text-input.
 */
function textAreaAutoHeight() {
    this.style.height = ''; // Resets height, in case text is removed
    this.style.height = `${this.scrollHeight}px`;
}

/**
 * This set an inputs width to match its content,
 * handy, when you need a unit (ex. kr.) to lean right against the input.
 */
function inputAutoWidth(e) {
    const input = e.target ? e.target : e;
    input.style.width = ''; // Resets height, in case text is removed
    input.style.width = `${input.scrollWidth}px`;
}

/**
 * Simply adds a class to target field if it has a value and removes it if not.
 *
 * @param {Object || HTMLElement} e - Event or field element
 */
function checkFieldValue(e) {
    const field = e.target || e;
    field.value ? field.classList.add('has-text') : field.classList.remove('has-text');
}

function setupRangeSlider(form) {
    const container = form.querySelector('[data-action="noUiSlider"]');

    if (container) {
        const target = container.getAttribute('data-target');
        const rangeSlider = target ? document.getElementById(target) : container;
        const inputs = container.querySelectorAll('input');
        const min = container.getAttribute('data-min') || 1000;
        const max = container.getAttribute('data-max') || 45000;
        let minDefault = 0;
        let maxDeafult = 100;

        if (inputs) {
            minDefault = inputs[0].value;
            maxDeafult = inputs[1].value;

            inputs[0].addEventListener('change', function () {
                const strippedValue = this.value.replace('.', '');
                rangeSlider.noUiSlider.set([strippedValue, null]);
            });
            inputs[1].addEventListener('change', function () {
                const strippedValue = this.value.replace('.', '');
                rangeSlider.noUiSlider.set([null, strippedValue]);
            });
        };

        noUiSlider.create(rangeSlider, {
            range: {
                min: parseInt(min),
                max: parseInt(max)
            },
            step: 150,
            start: [parseInt(minDefault), parseInt(maxDeafult)],
            connect: true,
        });

        rangeSlider.noUiSlider.on('update', function (values, handle) {
            if (inputs) {
                inputs[0].value = formatNumber(Math.round(values[0]));
                inputs[1].value = formatNumber(Math.round(values[1]));
            }
        });


    }
}


function setupFileupload(form) {
    const inputs = form.querySelectorAll('.inputfile');
    Array.prototype.forEach.call(inputs, function (input) {
        const label = input.nextElementSibling,
            labelVal = label.innerHTML;

        console.log('virker!');

        input.addEventListener('change', function (e) {
            let fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();

            if (fileName)
                label.querySelector('span').innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });

        // // Firefox bug fix
        // input.addEventListener('focus', function () { input.classList.add('has-focus'); });
        // input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
    });
}

/**
 * Sets up 'change'-eventhandler as checkValidation on given fields
 *
 * @param {HTMLElement} form - Form fields to validate
 */

export function watchFormFields(selector = '.form[data-action*="form"]') {
    const forms = document.body.querySelectorAll(selector);

    if (forms) {
        for (let f = 0; f < forms.length; f++) {
            const fields = forms[f].querySelectorAll('input, select, textarea');

            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                checkFieldValue(field);
                field.addEventListener('keyup', checkFieldValue);

                if (field.hasAttribute('required')) {
                    field.addEventListener('change', checkValidation);
                }

                /* if (field.hasAttribute('data-auto-width')) {
                    inputAutoWidth(field);
                    field.addEventListener('input', inputAutoWidth);
                } */
            }

            setupRangeSlider(forms[f]);
            setupFileupload(forms[f]);
        }
    }
}

/**
 * Serializes form fields
 *
 * @param {HTMLElement} form - Form element to serialize  *
 * @returns {string} - Returns querystring of fields names and values
 */
export function serialize(form) {
    if (!form || form.nodeName !== 'FORM') {
        return;
    }
    let i, j;
    const q = [];
    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
        if (form.elements[i].name === '') {
            continue;
        }
        switch (form.elements[i].nodeName) {
            case 'INPUT':
                switch (form.elements[i].type) {
                    case 'text':
                    case 'tel':
                    case 'email':
                    case 'date':
                    case 'number':
                    case 'hidden':
                    case 'password':
                    case 'button':
                    case 'reset':
                    case 'submit':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                    case 'checkbox':
                    case 'radio':
                        if (form.elements[i].checked) {
                            q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        }
                        break;
                    case 'file':
                        break;
                }
                break;
            case 'TEXTAREA':
                q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                break;
            case 'SELECT':
                switch (form.elements[i].type) {
                    case 'select-one':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                    case 'select-multiple':
                        for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                            if (form.elements[i].options[j].selected) {
                                q.push(
                                    `${form.elements[i].name}=${encodeURIComponent(form.elements[i].options[j].value)}`
                                );
                            }
                        }
                        break;
                }
                break;
            case 'BUTTON':
                switch (form.elements[i].type) {
                    case 'reset':
                    case 'submit':
                    case 'button':
                        q.push(`${form.elements[i].name}=${encodeURIComponent(form.elements[i].value)}`);
                        break;
                }
                break;
        }
    }
    return q.join('&').replace(/%20/g, '+');
}

/**
 * Sets up Form submit eventhandler used when submitting form data through Mandrill
 *
 * @param {string} selector - querySelector string
 */
export function setupMandrilForms(selector = 'form[data-action*="form-mandrill"]') {
    //mandrill forms
    const mandrillForms = document.querySelectorAll(selector);

    function mandrillFormSubmit(e) {
        e.preventDefault();

        const form = e.target;
        if (form !== null) {
            if (validateForm(form)) {
                form.classList.add('form--working');

                const dealerSelect = form.querySelector('select[name=dealer-mail]');

                if (dealerSelect) {
                    const dealer = dealerSelect.querySelector(`option[value="${dealerSelect.value}"]`);

                    if (dealer && dealer.innerText !== 0) {
                        form.querySelector('input[name=dealer-name]').value = dealer.innerText;
                    }
                }

                const XHR = new XMLHttpRequest();
                const formData = serialize(form);

                // Define what happens
                XHR.onreadystatechange = function () {
                    if (this.readyState === this.DONE) {
                        if (XHR.onreadystatechange) {
                            XHR.onreadystatechange = null;
                            const json = JSON.parse(this.responseText);

                            if (json.status !== 'ok') {
                                const errorText = form.getAttribute('data-error-message');
                                alert(errorText);
                                window.console.warn(json);

                                form.classList.remove('form--working');
                            } else {
                                window.location = form.getAttribute('data-confirmation-url');
                            }
                        }
                    }
                };
                // Set up our request
                XHR.open('POST', '/umbraco/api/mail/sendmultinestedcontent', true);
                // Add the required HTTP header for form data POST requests
                XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                // Finally, send our data.
                XHR.send(formData);
            }
        }
    }

    for (let i = 0; i < mandrillForms.length; i++) {
        mandrillForms[i].addEventListener('submit', mandrillFormSubmit);
    }
}
