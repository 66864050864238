
/**
 * The Slider class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="slider"
 *
 * @param {HTMLElement} container
 * @constructor
 */
export class SimpleSteps {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;

        if (this.container) {

            const simpleStepModal = this.container.querySelector('.simple-steps__modal');
            const stepsIds = this.container.querySelectorAll('.simple-steps__navigation__item');

            let simpleStepModalId = simpleStepModal.getAttribute('data-step');
            let wait = false;

            for (let p = 0; p < stepsIds.length; p++) {
                const stepId = stepsIds[p];
                const id = stepId.getAttribute('data-step-id');

                stepId.addEventListener('click', function () {

                    if (simpleStepModalId < id) {
                        simpleStepModal.setAttribute('data-direction', 'forwards');
                    }
                    else {
                        simpleStepModal.setAttribute('data-direction', 'backwards');
                    }

                    simpleStepModal.setAttribute('data-step', id);
                    simpleStepModalId = id;
                });

            };

            simpleStepModal.addEventListener('wheel', function () {
                if (wait === false) {
                    event.preventDefault();
                    const currentStep = parseInt(simpleStepModal.getAttribute('data-step'));
                    wait = true;

                    setTimeout(() => {
                        wait = false;
                    }, 300);


                    debugger;
                    if (event.deltaY < 0) {
                        console.log('scrolling up');
                        if (currentStep > 1) {

                            const scrollId = currentStep - 1;

                            simpleStepModal.setAttribute('data-direction', 'backwards');
                            simpleStepModal.setAttribute('data-step',scrollId);
                            simpleStepModalId = scrollId;
                        }

                    }
                    else if (event.deltaY > 0) {
                        console.log('scrolling down');
                        if (currentStep < stepsIds.length) {

                            const scrollId = currentStep + 1;


                            simpleStepModal.setAttribute('data-direction', 'forwards');
                            simpleStepModal.setAttribute('data-step', scrollId);
                            simpleStepModalId = scrollId;
                        }
                    }


                }

            });

        }
    }
}

export function setupSimpleSteps(selector = '[data-action*="simple-steps"]') {
    const simpleStepsSections = document.body.querySelectorAll(selector);
    for (let i = 0; i < simpleStepsSections.length; i++) {
        void new SimpleSteps(simpleStepsSections[i]);
    }
}
