import { getElementScroll} from './elementProperties';
import { scrollLocked } from './scrollLock';
import { onScroll } from './scroll';

/**
 *
 * @param {HTMLElement} element - element to add sticky class to
 * @param {string} className - sticky class name to add on scroll
 * @param {number} scrollInPixels - number of pixels before activating scroll
 * @param {string} goingUpClass - class added when scrolling up
 */
export function stickyNavOnScroll(element, className = 'nav--sticky', scrollInPixels = 0, goingUpClass = 'nav--going-up') {
    let lastScrollPosition;
    const header = document.body.querySelector('.header__wrap') || document.body.querySelector('.header--simple');
    const headerHeight = header ? header.offsetHeight - 2 : 200;

    const scrollHandler = () => {
        if (!scrollLocked) {
            const windowScroll = getElementScroll();

            if (windowScroll.top > scrollInPixels) {
                element.classList.add(className);

                if (lastScrollPosition > windowScroll.top) {
                    element.classList.add(goingUpClass);
                } else {
                    element.classList.remove(goingUpClass);
                }
                lastScrollPosition = windowScroll.top;
            } else {
                element.classList.remove(className);
                element.classList.remove(goingUpClass);
            }

            // Added white background after menu passes header
            if (windowScroll.top > headerHeight) {
                element.classList.add('nav--white-bg');
            } else {
                element.classList.remove('nav--white-bg');
            }
        }
    };

    onScroll(scrollHandler, true);
}
