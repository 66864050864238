
import Flickity from 'flickity';
// import { currentWindowWidth, onWindowResize } from '../utils/windowResize';
import { disableScrollLock, enableScrollLock } from '../utils/scrollLock';


/**
 * The Gallery class
 * Default element selector is data-action*="gallery"
 *
 * @param {HTMLElement} container
 * @constructor
 */

export class Gallery {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} container
     */
    constructor(container) {
        this.container = container;

        if (this.container) {
            // const gallerySlides = galleryModal.querySelector('.gallery__slides');

            this.flktyGallery = new Flickity(this.container.querySelector('.gallery__slides'), {
                // on: {
                //     ready() {
                //         galleryModal.classList.add('open');
                //     }
                // },
                cellAlign: 'center',
                prevNextButtons: false,
                percentPosition: false,
                wrapAround: true,
                selectedAttraction: 0.02,
                friction: 0.20,
                pageDots: false,
                draggable: true,
            });

            this.bindEvents();
        }
    }

    bindEvents() {
        const scope = this;

        //Custom navigation
        // elements
        const cellsButtonGroup = scope.container.querySelector('.gallery__navigation__group');
        const cellsButtons = cellsButtonGroup ? Array.from(cellsButtonGroup.children) : null;


        function setDot(index) {
            const previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
            const selectedButton = cellsButtonGroup.children[index];
            previousSelectedButton.classList.remove('is-selected');
            selectedButton.classList.add('is-selected');
        }

        if (cellsButtons)
        {
            // update buttons on select
            scope.flktyGallery.on('select', function () {
                setDot(scope.flktyGallery.selectedIndex);
            });

            // cell select


            cellsButtonGroup.addEventListener('click', function (event) {
                if (!event.target.matches('.dot')) {
                    return;
                }
                const index = cellsButtons.indexOf(event.target);
                scope.flktyGallery.select(index, false, false);
            });



            // previous
            const previousButton = scope.container.querySelector('.gallery__navigation .btn--previous');
            if (previousButton) {
                previousButton.addEventListener('click', function () {
                    scope.flktyGallery.previous();
                });
            }

            // next
            const nextButton = scope.container.querySelector('.gallery__navigation .btn--next');
            if (nextButton) {
                nextButton.addEventListener('click', function () {
                    scope.flktyGallery.next();
                });
            }
        }
    }

}
export function setupGallery(selector = '[data-action*="gallery"]') {
    const galleries = document.body.querySelectorAll(selector);

    for (let i = 0; i < galleries.length; i++) {
        void new Gallery(galleries[i]);
    }
}

// function initGallery(galleryModal) {


//     //Close flikity and modal
//     galleryModal.querySelector('.gallery__close').addEventListener('click', function () {

//         galleryModal.classList.remove('open');

//         setTimeout(() => {
//             disableScrollLock();
//             flktyGallery.destroy();
//             setDot(0);

//         }, 500);
//     });
// }



// export function setupGallery() {
//     // const galleryElems = document.querySelectorAll('.gallery__item');

//     // if (galleryElems) {
//     //     for (let index = 0; index < galleryElems.length; index++) {
//     //         const galleryElem = galleryElems[index];
//     //         const galleryModal = galleryElem.querySelector('.gallery');


//     //         galleryElem.querySelector('.gallery__item__content').addEventListener('click', function () {
//     //             // enableScrollLock();
//     //             initGallery(galleryModal);
//     //         });
//     //     }
//     // }
// }


