//import { gsap } from 'gsap';
//import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { currentWindowWidth, onWindowResize } from './windowResize';

gsap.registerPlugin(ScrollTrigger);

export const transitionEndEventName = detectStyleDeclatationName();
export const animationEndEventName = detectStyleDeclatationName('animation');

/**
 * Detects which prefixed eventName is in CSSStyleDeclaration
 *
 * @param {string} eventTypeName - choose 'transition' or 'animation'
 * @returns {string}
 */
export function detectStyleDeclatationName(eventTypeName = 'transition') {
    const transitions = {
        transition: `${eventTypeName}end`,
        OTransition: `o${eventTypeName}end`,
        MozTransition: `${eventTypeName}end`,
        WebkitTransition: `webkit${eventTypeName}end`
    };

    const el = document.createElement('div');

    for (const t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
    return '';
}

export function cssAnimateNewHeight(container, classTarget = container, classOnBoth = false) {
    let newHeight;
    const prevHeight = container.offsetHeight;

    const delayedClose = () => {
        if (classOnBoth) {
            container.classList.remove('open');
            classTarget.classList.remove('open');
        } else {
            classTarget.classList.remove('open');
        }

        classTarget.blur(); // removes focus if its a button
        container.removeEventListener(transitionEndEventName, delayedClose);
    };

    container.removeAttribute('style');

    if (classTarget.classList.contains('open')) {
        newHeight = 0;
        container.addEventListener(transitionEndEventName, delayedClose);

        if (classOnBoth) {
            classTarget.classList.remove('open');
        }
    } else {
        classTarget.classList.add('open');

        if (classOnBoth) {
            container.classList.add('open');
        }

        newHeight = container.offsetHeight;
    }

    container.style.height = `${prevHeight}px`;

    setTimeout(() => {
        container.style.height = `${newHeight}px`;
    }, 10);
}

export function scrollAnimations() {

    if (currentWindowWidth >= 600) {
        const headerElem = document.querySelector('.header--zoom .header__wrap');
        if (headerElem) {
            const headerImg = headerElem.querySelector('img');

            if (headerImg) {
                gsap.to(headerImg, {
                    objectPosition: '50% 80%',
                    scale: 1.15,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: headerElem,
                        //markers: true,
                        start: 'top top',
                        end: '+=60%',
                        scrub: 0.5
                    },
                });
            }
        }

        const categoryOverview = document.body.querySelector('.category-overview');
        if (categoryOverview) {
            const categoryImages = categoryOverview.querySelectorAll('.category-overview__img');

            if (categoryImages.length) {
                const skipFirstCat = Array.from(categoryImages).slice(1);

                gsap.from(skipFirstCat, {
                    scale: 1.2,
                    stagger: 1 / skipFirstCat.length,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: categoryOverview,
                        //markers: true,
                        scrub: true
                    },
                });
            }
        }

        const videoContents = document.body.querySelectorAll('.video-module__content');
        if (videoContents.length && currentWindowWidth >= 1024) {
            for (let c = 0; c < videoContents.length; c++) {
                const videoContent = videoContents[c];
                const video = videoContent.parentNode.querySelector('.video');

                gsap.from(video, {
                    scale: 1.2,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: videoContent,
                        //markers: true,
                        start: 'top 20%',
                        end: '+=90%',
                        scrub: true
                    },
                });
            }
        }

        const fullCtas = document.body.querySelectorAll('.fullscreen-cta');

        for (let fs = 0; fs < fullCtas.length; fs++) {
            const fullCtaElem = fullCtas[fs];
            const fullImg = fullCtaElem.querySelector('img');
            const content = fullCtaElem.querySelector('.content');

            if (fullImg && content) {
                const fullCtaTimeline = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: fullCtaElem,
                        //markers: true,
                        start: 'top +=60%',
                        end: 'bottom +=90%',
                        scrub: true
                    }
                });

                fullCtaTimeline.from(fullImg, {
                    scale: 1.2,
                    duration: 1,
                    ease: 'power1.out',
                });

                fullCtaTimeline.from(content, {
                    opacity: 0,
                    y: 30,
                    duration: 0.4,
                    ease: 'power1.out'
                }, '-=0.4');
            }
        }
    }

    const newsEntrence = document.body.querySelector('.news-entrence');
    if (newsEntrence) {
        const curtain = newsEntrence.querySelector('.news-entrence__picture i');

        gsap.from(curtain, {
            x: 0,
            ease: 'power2.out',
            scrollTrigger: {
                trigger: newsEntrence,
                start: 'top 70%'
            }
        });
    }

    // This animation needs some bugfixing!
    // It has been outcommented due to iregular scrollTrigger start/end placement.

    // ------------------------------------------------
    if (currentWindowWidth >= 1024) {
        const textImages = document.body.querySelectorAll('.text-image');

        for (let ti = 0; ti < textImages.length; ti++) {
            const textImage = textImages[ti];
            const curtain = textImage.querySelector('i');
            const img = textImage.querySelector('img');

            if (curtain && img) {

                const isReversed = textImage.querySelector('.grid-row').classList.contains('grid-row--reverse');

                const tl = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: textImage,
                        start: 'top center',
                        //markers: true,
                        // end: '+=70%',
                        // scrub: 1
                    }
                });

                tl.from(curtain, {
                    x: 0,
                    ease: 'power3.out',
                    duration: 1.2,
                });

                tl.from(img, {
                    xPercent: isReversed ? -10 : 10,
                    scale: 1.1,
                    ease: 'power3.out',
                    duration: 1,
                }, '<');

                img.addEventListener('lazyloaded', () => {
                    ScrollTrigger.refresh();
                });
            }
        }
    }

    onWindowResize(ScrollTrigger.refresh);
}
