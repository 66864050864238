import { cssAnimateNewHeight } from '../utils/animation';

let activeAccordion;
let index = 0;

/**
 * The Accordion class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="accordion"
 * If data-action is set to "accordion-switch", active accordions will close when another is opened.
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class Accordion {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} trigger
     */
    constructor(trigger) {
        this.dom = {
            trigger,
            container: trigger.parentNode.querySelector('.accordion__content') || trigger.nextElementSibling
        };

        this.settings = {
            type:  trigger.getAttribute('data-action')
        };

        this.index = index;

        index++;

        this.dom.trigger.addEventListener('click', e => {
            e.preventDefault();
            if (activeAccordion && this.index !== activeAccordion.index) {
                // Dobbelt check added to prevent delayed dobbelt opening
                if (activeAccordion.dom.container.classList.contains('open')) {
                    cssAnimateNewHeight(activeAccordion.dom.container, activeAccordion.dom.trigger, true);
                    activeAccordion.dom.trigger.setAttribute('aria-expanded', 'false');
                }
            }

            cssAnimateNewHeight(this.dom.container, this.dom.trigger, true);

            // Set the inverted value of aria-expanded
            const ariaExpanded = this.dom.trigger.getAttribute('aria-expanded') || 'false';
            this.dom.trigger.setAttribute('aria-expanded', ariaExpanded === 'false');

            if (this.settings.type === 'accordion-switch') {
                activeAccordion = this;
            }

            if (this.settings.type === 'accordion-once') {
                this.dom.trigger.classList.add('hidden');
            }
        });
    }
}

function openAccordByHashId() {
    if (window.location.hash.indexOf('accordion') > -1) {
        const accordionContent = document.getElementById(window.location.hash.replace('#', ''));
        if (accordionContent) {
            const accord = document.getElementById(accordionContent.getAttribute('aria-labelledby'));
            accord.click();
        }
    }
}

/**
 * Initialise Accordions with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupAccordions(selector = '[data-action*="accordion"]') {
    const accordions = document.body.querySelectorAll(selector);

    if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
            void new Accordion(accordions[i]);
        }

        openAccordByHashId();
    }
}
