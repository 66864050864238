import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { currentWindowWidth} from '../utils/windowResize';


export let nav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => {
                nav.classList.add('going-up');
            }, 50);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');

        //First lazyload images when overlay is open, and not mobile
        if (currentWindowWidth > 1023) {
            const lazyElements = nav.querySelectorAll('.lazyload-overlay');
            for (let i = 0; i < lazyElements.length; i++) {
                const lazyElement = lazyElements[i];

                lazyElement.classList.remove('lazyload-overlay');
                lazyElement.classList.add('lazyload');
            }

        }
    }

}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }
    }
}
