import { onReady } from './utils/onReady';
import { setupNav } from './components/nav';
import { setupAccordions } from './components/accordion';
import { setupVideos } from './components/video';
import { setupAnchorLinkScroll, scrollToUrlHash } from './components/anchors';
import { setupFilters } from './components/filter';
import { setupOverlay } from './components/overlay';
import { setupSliders } from './components/slider';
import { setupGallery } from './components/gallery';
/* import lazySizes from 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'; */
import { extendStickyHeader } from './components/stickyHeader';
import { setupSimpleSteps } from './components/simple-steps';
import { watchFormFields } from './components/form';
import { scrollAnimations } from './utils/animation';

function init() {
    document.body.classList.remove('standby');
    lazySizes.cfg.loadMode = 3;
    lazySizes.cfg.preloadAfterLoad = false;
    lazySizes.autoSizer.checkElems();

    scrollToUrlHash();
    setupAnchorLinkScroll();

    setupNav('.nav');

    onReady(() => {

        extendStickyHeader();

        setupOverlay();

        setupVideos();

        setupFilters();

        setupSliders();

        setupGallery();

        setupSimpleSteps();

        watchFormFields();

        setupAccordions();

        scrollAnimations();
    });


    document.addEventListener('readystatechange', () => {
        extendStickyHeader();
    });
}

init();
